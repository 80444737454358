import { brand } from './common/brand'
import { footer } from './common/footer'
import { headerV2 } from './common/headerV2'

export default {
  title: 'Free Resume Review',
  slug: 'resume-review',
  seo: {
    pageTitle: 'Free Resume Review',
    description:
      "Is your resume strong enough to land your dream job? Upload your resume today and get free feedback from one of TopResume's expert resume writers.",
    openGraphTitle: null,
    openGraphDescription: null,
    openGraphImage: null,
    noIndex: false,
    doNotDeployToProduction: false,
    pageType: null,
    canonicalUrl: null,
  },
  ...headerV2,
  ...brand,
  ...footer,
}
